<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <label>进行中任务列表</label>
      <div>
        <el-button type="primary" size="small" @click.stop="handleEdit(false,'')">创建任务</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据" @row-click="handleRowClick">
        <el-table-column prop="name" label="任务名称"></el-table-column>
        <!--<el-table-column prop="interruptFlag" label="是否中断"></el-table-column>-->
        <el-table-column prop="status" label="状态">
           <template slot-scope="scope">
              <span v-if="scope.row.status == 0">未派发</span>
              <span v-if="scope.row.status == 1">已派发</span>
              <span v-if="scope.row.status == 2">关闭</span>
           </template>
        </el-table-column>
        <el-table-column label="主任务操作">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click.stop="handleEdit(true,scope.row)"></i>
            <i class="el-icon-delete" @click.stop="handleClose(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
export default {
  name: '',
  components: {
    CommonPagination,
  },
  data() {
    return {
      tableData: [],
      editDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      type: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    //get tasks
    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/task/v1/task/page/',
        params: {
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },

//detail edit
    handleRowClick(row){
      let flag = !row.exeCount && !row.status
      this.$router.push({
        path:`/task/taskDetailEdit`,
        query:{
          id:row.id,
          isModify: flag,
          status:row.status
        }
      })
    },

//task close
    handleClose(row){
      let flag = !row.exeCount && !row.status
      if(row.status==0){
        this.$message({
          type:'error',
          message: "此任务还未完成，请派发任务",
          duration: 1000
        })
      }else if(row.status==2){
        this.$message({
          type:'error',
          message:"此任务已经关闭，请勿重复操作",
          duration:1000
        })
      }else{
        this.$axios({
          method: 'post',
          url: `api/csp/task/v1/task/close/${row.id}`,
          params:{
            taskId:row.id
          }
        }).then((response)=> {
          console.log(response)
          let data = response.data
        this.$message({
          type:'error',
          message: data.msg,
          duration: 1000
        })
      })
      }
    },


    // 分页
    handleCurPageChange(){
      this.getList()
    },

    //edit or create
    handleEdit(flag,row){
      let options = ''
      console.log(row.id)
      if(flag){

        options = row
        this.type = 1
      }else{
        options = {
          attachments: '',
          content: '',
          gmtCreated: '',
          gmtEnd: '',
          id: '',
          interruptFlag: '',
          name: '',
          recordingType: '',
          remark: '',
        }
        this.type = 0
      }
      this.$router.push({
        path: `/task/taskCreate`,
        query:{
          options: JSON.stringify(options),
          type: this.type
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
