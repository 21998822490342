var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("进行中任务列表")]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleEdit(false, "")
                  },
                },
              },
              [_vm._v("创建任务")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "任务名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("span", [_vm._v("未派发")])
                          : _vm._e(),
                        scope.row.status == 1
                          ? _c("span", [_vm._v("已派发")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("关闭")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "主任务操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleEdit(true, scope.row)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleClose(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }